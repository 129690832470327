<template>
  <div>
    <a-radio-group v-model="selected_permissions">
      <a-radio v-for="(option, i) in options" :key="i" :value="option.value">{{
        option.label
      }}</a-radio>
    </a-radio-group>
  </div>
</template>

<script>
const NONE = 0;
const LOOKUP = 1;
const USER = LOOKUP | 2;
const ADMIN = USER | 4;
const GUEST = LOOKUP | 8;

export default {
  props: ["permissions"],
  data() {
    return {
      options: [
        {
          value: NONE,
          label: "Blocked",
        },
        {
          value: GUEST,
          label: "Guest",
        },
        {
          value: USER,
          label: "User",
        },
        {
          value: ADMIN,
          label: "Admin",
        },
      ],
    };
  },
  computed: {
    selected_permissions: {
      get() {
        return this.permissions;
      },
      set(val) {
        this.$emit("set-permissions", val);
      },
    },
  },
};
</script>

<style>
</style>